<template>
	<div class="mt-20 md:mt-0">
		<div
			class="
        flex
        items-center
        justify-between
        mb-4
        bg-verdedark
        w-full
        fixed
        md:static
      "
		>
			<div class="flex w-2/5 items-center justify-between">
				<span class="text-white font-bold ml-2">Clientes</span>
				<div class="font-bold">
					<span class="text-white">Ordenar por: </span>
					<select
						id="select"
						class="
              py-1
              h-8
              w-40
              rounded-lg
              bg-verdedark
              border border-white
              text-white
              px-4
              focus:outline-none
            "
						v-model="ordenarAlfabeticamente"
					>
						<option :value="true">Nombre cliente</option>
						<option :value="false">Zona cliente</option>
					</select>
				</div>
			</div>
			<div class="flex justify-center">
				<router-link to="/AgregarCliente"
					><button
						class="
              text-white
              font-bold
              py-2
              px-4
              inline-flex
              items-center
              focus:outline-none
            "
					>
						<svg
							id="Capa_1"
							enable-background="new 0 0 551.13 551.13"
							height="512"
							viewBox="0 0 551.13 551.13"
							width="512"
							class="h-6 w-6 mr-2 fill-current text-white"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="m275.565 0c-151.944 0-275.565 123.621-275.565 275.565s123.621 275.565 275.565 275.565 275.565-123.621 275.565-275.565-123.621-275.565-275.565-275.565zm0 516.685c-132.955 0-241.119-108.164-241.119-241.119s108.164-241.12 241.119-241.12 241.12 108.164 241.12 241.119-108.165 241.12-241.12 241.12z"
							/>
							<path
								d="m292.788 137.783h-34.446v120.56h-120.56v34.446h120.56v120.56h34.446v-120.56h120.56v-34.446h-120.56z"
							/>
						</svg>
						<span class="text-white underline">Añadir Cliente</span>
					</button></router-link
				>
			</div>
		</div>
		<div class="flex items-center justify-center pb-10 mt-20 mx-10 md:mt-0">
			<table id="Vendedores" class="table-auto mt-4 ml-64 md:ml-0">
				<thead>
					<tr>
						<th class="border-2 px-4 py-2">Clientes</th>
						<th class="border-2 px-4 py-2">C.I.</th>
						<th class="border-2 px-4 py-2">Teléfono</th>
						<th class="border-2 px-4 py-2">Dirección</th>
						<th></th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(Cliente, k) in clientesQuery" :key="k">
						<td class="border-2 px-4 py-4">
							<router-link
								class="hover:text-blue-500 hover:underline"
								:to="{
									name: 'DetalleCliente',
									params: {
										id: parseInt(Cliente.id),
										name: Cliente.name.replace(/\s/g, '_')
									}
								}"
							>
								{{ Cliente.name }}
							</router-link>
						</td>
						<td class="text-center border-2 px-4 py-4">
							{{ Cliente.cedula_identidad }}
						</td>
						<td class="text-center border-2 px-4 py-4">
							{{ Cliente.numero_telefono_movil }}
						</td>
						<td class="text-center border-2 px-4 py-1">
							{{ Cliente.address }}
						</td>
						<td scope="row" class="px-2 py-1">
							<router-link
								:to="{
									name: 'EditarCliente',
									params: {
										id: parseInt(Cliente.id)
									}
								}"
							>
								<svg
									width="30"
									height="30"
									viewBox="0 0 30 30"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
									class="
                    h-6
                    w-6
                    mr-2
                    hover:opacity-100
                    cursor-pointer
                    fill-current
                    text-blue-500
                    opacity-75
                  "
								>
									<path
										d="M4.17473 2.06016C5.68679 0.550767 8.13538 0.550767 9.64744 2.06016L11.0174 3.4376L25.6002 18.0127L25.6312 18.0439C25.6387 18.0514 25.6387 18.0594 25.6467 18.0594C25.6622 18.0826 25.6854 18.1056 25.7007 18.1289C25.7007 18.1366 25.7087 18.1366 25.7087 18.1444C25.7242 18.1676 25.7317 18.1831 25.7474 18.2064C25.7549 18.2141 25.7549 18.2216 25.7627 18.2296C25.7704 18.2529 25.7782 18.2684 25.7861 18.2916C25.7861 18.2991 25.7936 18.2991 25.7936 18.3071L29.0291 28.0366C29.124 28.3136 29.0519 28.6203 28.8434 28.8259C28.6969 28.9704 28.4994 29.0513 28.2938 29.0506C28.2098 29.0491 28.1265 29.036 28.0461 29.0118L18.3242 25.7686C18.3166 25.7686 18.3166 25.7686 18.3087 25.7611C18.2842 25.7538 18.2607 25.7434 18.2392 25.7299C18.2331 25.7291 18.2278 25.7265 18.2234 25.7224C18.2004 25.7069 18.1694 25.6911 18.1462 25.6756C18.1232 25.6604 18.0997 25.6371 18.0765 25.6216C18.0687 25.6136 18.0612 25.6136 18.0612 25.6061C18.0532 25.5984 18.0377 25.5909 18.03 25.5751L2.07722 9.62239C0.567829 8.11033 0.567829 5.66174 2.07722 4.14992L4.17473 2.06016ZM18.5718 23.9495L23.9591 18.5625L10.4755 5.07895L5.08826 10.4659L18.5718 23.9495ZM24.7179 19.9944L20.0115 24.7006L27.0786 27.0535L24.7179 19.9944ZM3.16847 8.53865L3.98903 9.36695L9.3765 3.97947L8.54796 3.15141C7.64169 2.24587 6.17273 2.24587 5.26622 3.15141L3.16096 5.25667C2.26148 6.16705 2.26487 7.63262 3.16847 8.53865Z"
									/>
								</svg>
							</router-link>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="flex justify-between">
			<div class="pl-32 py-4">
				<button
					class="
            bg-gray-500
            text-white
            rounded-md
            border
            w-24
            text-lg
            font-bold
            h-10
            hover:bg-azul
          "
					v-if="dataInicio > 0"
					@click="cargarDataAnterior()"
				>
					Anterior
				</button>
			</div>
			<div class="pr-40 py-4">
				<button
					class="
            bg-gray-500
            text-white
            rounded-md
            border
            w-24
            text-lg
            font-bold
            h-10
            hover:bg-azul
          "
					v-if="continuaData"
					@click="cargarDataSiguiente()"
				>
					Siguiente
				</button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "Clientes",

	data: function() {
		return {
			clientesQuery: [],
			queryClientes: [],
			dataInicio: 0,
			dataFin: 0,
			continuaData: false,
			ordenarAlfabeticamente: true,
			paginacion: 20
		};
	},
	mounted() {
		this.dataFin = this.paginacion;
		//this.cargaClientes();
	},
	apollo: {
		queryClientes: {
			query: require("@/graphql/queries/saberClientes.gql"),
			variables() {
				// Use vue reactive properties here
				return {
					dataInicio: this.dataInicio,
					dataFin: this.dataFin,
					ordenarAlpha: this.ordenarAlfabeticamente
				};
			},
			fetchPolicy: "cache-and-network",
			update: data => data.saberClientes,
			error: (error, vm) => (vm.feedback.errorSearched = true),
			watchLoading(isLoading, countModifier) {
				// isLoading is a boolean
				// countModifier is either 1 or -1
				this.$store.state.isLoading = isLoading;
			}
		}
	},
	watch: {
		queryClientes: function() {
			this.clientesQuery = this.queryClientes.resultado_clientes;
			this.continuaData = this.queryClientes.continua_data;
		}
	},
	methods: {
		cargarDataSiguiente() {
			this.dataInicio += this.paginacion;
			this.dataFin += this.paginacion;
			this.cargaClientes();
		},
		cargarDataAnterior() {
			this.dataInicio -= this.paginacion;
			this.dataFin -= this.paginacion;
			this.cargaClientes();
		},
		nuevoQueryClientes() {
			this.dataInicio = 0;
			this.dataFin = 20;
			this.cargaClientes();
		},
		async cargaClientes() {
			await this.$apollo
				.query({
					query: require("@/graphql/queries/saberClientes.gql"),
					variables: {
						dataInicio: this.dataInicio,
						dataFin: this.dataFin,
						ordenarAlpha: this.ordenarAlfabeticamente
					},
					fetchPolicy: "no-cache"
				})
				.then(data => {
					this.clientesQuery = data.data.saberClientes.resultado_clientes;
					this.continuaData = data.data.saberClientes.continua_data;
				})
				.catch(error => {
					console.log(error);
				});
		}
	}
};
</script>
